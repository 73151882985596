export let rulesEdie = {
  name: {
    type: ["Number", "String"],
    name: "水表名字",
    required: true,
  },
  phone: {
    type: "Phone",
    name: "联系电话",
    required: false,
  },
  address: {
    type: ["String"],
    name: "地址",
    required: false,
  },
  areaId: {
    type: ["Number"],
    name: "片区",
    required: true,
  },
  useWaterType: {
    type: ["Number"],
    name: "用水性质",
    required: true,
  },
  billingType: {
    type: ["Number"],
    name: "计费方式",
    required: true,
  },
  billingCycleType: {
    type: ["Number"],
    name: "计费周期",
    required: true,
  },
  perPrice: {
    type: ["String", "Number"],
    name: "单价/费用",
    required: true,
  },
};
export let rulesAdd = {
  name: {
    type: ["Number", "String"],
    name: "水表名字",
    required: true,
  },
  phone: {
    type: "Phone",
    name: "联系电话",
    required: false,
  },
  address: {
    type: "String",
    name: "地址",
    required: false,
  },
  areaId: {
    type: ["Number"],
    name: "片区",
    required: true,
  },
  useWaterType: {
    type: ["Number"],
    name: "用水性质",
    required: true,
  },
  billingType: {
    type: ["Number"],
    name: "计费方式",
    required: true,
  },
  billingCycleType: {
    type: ["Number"],
    name: "计费周期",
    required: true,
  },
  perPrice: {
    type: ["String", "Number"],
    name: "单价/费用",
    required: true,
  },
  lastDosage: {
    type: ["String", "Number"],
    name: "最后一次读数用量",
  },
  lastRecordTime: {
    type: ["String"],
    name: "最后一次记录时间",
    required: true,
  },
};
export let presetPrice = [
  {
    val: 0.35,
  },
  {
    val: 0.65,
  },
  {
    val: 0.75,
  },
  {
    val: 0.85,
  },
];

